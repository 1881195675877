const addresses = {
  multiCall: {
    1: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    97: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  staking: {
    97: '0xbb0C2d289D53A618C8391DDd9c29905D850E1010'
  },
  dione: {
    1: '0x89B69F2d1adffA9A253d40840B6Baa7fC903D697',
    97: '0x0E3A0875d54Cb77f2D94C60bDdC071e92026F40D'
  }
}

export default addresses;
