import { Modal } from "../../context/modal";
import './style.scss';

import ButtonPrimary from '../ButtonPrimary';
import { ethers, BigNumber } from "ethers";
import { useState } from "react";

export default function AmountModal({ onDismiss, onStake, balance }) {
  const [amount, setAmount] = useState(BigNumber.from(0));
  const [amountShow, setAmountShow] = useState('');
  const [loading, setLoading] = useState(false);

  const applyPercent = (amount) => {
    let _percentAmount = balance.mul(amount).div(100)
    setAmount(_percentAmount)
    setAmountShow(ethers.utils.formatUnits(_percentAmount, '9'));
  }

  const onType = (e) => {
    const value = e.target.value;

    setAmountShow(value);
    setAmount(
      ethers.utils.parseUnits(value, '9')
    )
  }

  const handleStake = async () => {
    setLoading(true)
    await onStake([amount], {});
    setLoading(false);
    onDismiss()
  }

  return (
    <Modal onDismiss={onDismiss} style={{ maxWidth: 420 }} title="Stake Amount">
      <div className="amount-modal">
        <div className="amount-container">
          <input disabled={loading} className="amount-modal__input" value={amountShow} onChange={onType} placeholder='0.00' />

          <div className="amount-modal__btngp">
            <button disabled={loading} className="amount-modal__state" onClick={applyPercent.bind(this, 25)}><span>25%</span></button>
            <button disabled={loading} className="amount-modal__state" onClick={applyPercent.bind(this, 50)}><span>50%</span></button>
            <button disabled={loading} className="amount-modal__state" onClick={applyPercent.bind(this, 75)}><span>75%</span></button>
            <button disabled={loading} className="amount-modal__state" onClick={applyPercent.bind(this, 100)}><span>MAX</span></button>
          </div>
        </div>
        <div
          className="modal-btn"
        >
          <ButtonPrimary
            onClick={handleStake}
            disabled={loading}
            >{loading ? 'Staking...' : "Stake Dione"}</ButtonPrimary>
        </div>
      </div>
    </Modal>
  );
}
