import { MaxUint256 } from '@ethersproject/constants'
import { useAccount } from 'wagmi'
import { useCallback, useMemo, useState } from 'react'
import { calculateGasMargin } from '../utils'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { useTokenContract } from './useContract'
import useTokenAllowance from './useTokenAllowances'
import { toast } from 'react-hot-toast'

export const ApprovalState = {
  'UNKNOWN': 0,
  'NOT_APPROVED': 1,
  'PENDING': 2,
  'APPROVED': 3
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove,
  token,
  spender,
) {
  const [approving, setApproving] = useState(false);
  const { address: account } = useAccount()
  const { callWithGasPrice } = useCallWithGasPrice()
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)

  // check the current approval status
  const approvalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency?.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    if(approving) return ApprovalState.PENDING;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, spender, approve])

  const tokenContract = useTokenContract(token)

  const approve = useCallback(async () => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return undefined
    }
    if (!token) {
      console.error('no token')
      return undefined
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return undefined
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return undefined
    }

    if (!spender) {
      console.error('no spender')
      return undefined
    }

    let useExact = false
    setApproving(true);

    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.toString()).catch(() => {
        console.error('estimate gas failure')
        return null
      })
    })

    if (!estimatedGas) {
      setApproving(false);
      return undefined
    }

    return callWithGasPrice(
      tokenContract,
      'approve',
      [spender, useExact ? amountToApprove.toString() : MaxUint256],
      {
        gasLimit: calculateGasMargin(estimatedGas),
      },
    )
      .then((response) => {
        setApproving(false);
        toast.success('Approval request submitted!')
      })
      .catch((error) => {
        setApproving(false);
        toast.error('Failed to approve token')
        if (error?.code !== 4001) {

        }
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, callWithGasPrice])

  return [approvalState, approve]
}
