import { getAddress } from "@ethersproject/address"
import memoize from 'lodash/memoize'
import addresses from "../constants/addresses";

export const getContractAddress = (address, chainId) => {
    return address[chainId] ? address[chainId] : address[97]
}

export const isAddress = memoize((value) => {
    try {
      return getAddress(value)
    } catch {
      return false
    }
  })


export const getMulticallAddress = (chainId) => {
  return getContractAddress(addresses.multiCall, chainId)
}

export const getStakingAddress = (chainId) => {
  return getContractAddress(addresses.staking, chainId)
}

export const getDioneAddress = (chainId) => {
  return getContractAddress(addresses.dione, chainId)
}
