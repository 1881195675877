import {invert} from "lodash";
import memoize from "lodash/memoize";

export const CHAIN_QUERY_NAME = {
  1: 'eth',
  5: 'goerli',
  56: 'bsc',
  97: 'bscTestnet',
}

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName) => {
  if (!chainName) return CHAIN_QUERY_NAME_TO_ID['bscTestnet']
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : CHAIN_QUERY_NAME_TO_ID['bscTestnet']
})
